import React, { useEffect, useState } from 'react';
import { YFadeIn } from '../others/animate';
import { GoGlobe, GoLinkExternal } from 'react-icons/go';
import { BsCodeSlash, BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import ZoomImage from '../others/zoomImage';

export default function ECollab({ cur }) {
    const [showModal, setShowModal] = useState(false);
    const screenWidth = useWindowSize();

    useEffect(() => {
        setShowModal(false)
    }, [cur])

    if (cur !== 4)
        return (<></>)
    return (
        <>
            <ZoomImage showModal={showModal} setShowModal={setShowModal} />
            <div className={`blur-featured`} style={{}}>
                <img src={'/images/projects/ecollab.gif'} style={{ opacity: screenWidth > 1500 ? 0.8 : screenWidth > 900 ? 0.3 : 0.2, height: '80vh', maxWidth: '100%', right: 0, bottom: '10vh', maxHeight: '100vh', position: 'absolute' }} />
                <div style={{ width: '100%', margin: 'auto' }}>
                    <YFadeIn key={cur}><div className='hero-flex'>
                        <div style={{ fontSize: 28, }} className='hero-texts'>
                            <div className='hero-heading'>PROJECT</div>
                            <a href='https://ecollab.live/'
                                target='_blank'><h3 className='y-green'><GoGlobe /> eCollab</h3></a>
                            <div className='mb-3 project-summary'>eSports collaboration in real-time.</div>
                            <span>A real-time eSports collaboration tool for coach, analyst, and competitive teams.
                                <br />
                                <li><a href='https://ecollab.live/r/ljQFSDEsgy' target='_blank' className='y-green'>View Saved Map</a></li>
                            </span>
                            <div className='mt-3 project-preview y-green'>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/ecollab.gif',
                                    title: 'Preview',
                                    description: <a href='https://ecollab.live' target='_blank' className='y-green'>www.ecollab.live</a>
                                })}><img src='/images/projects/ecollab.gif' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/eCollab/ec.png',
                                    title: 'Draw on Map',
                                    description: `Use pen, circle, texts, and markers of different types, sizes, and colors.`
                                })}><img src='/images/projects/eCollab/ec.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/eCollab/ecPlain.png',
                                    title: 'Draw on Plain Board',
                                    description: `Draw on plain backgrounds of different colors.`
                                })}><img src='/images/projects/eCollab/ecPlain.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/eCollab/goLive.png',
                                    title: 'Share Map',
                                    description: `Share your saved map link, or go live with real-time updates.`
                                })}><img src='/images/projects/eCollab/goLive.png' /></div>
                            </div>
                            <div className='mt-3 exp-tools'><BsCodeSlash className='y-green' /> Next.js, Firebase, Konva.js, GUN.js, Canvas.</div>

                            <div className="hero-social">
                                <a href="https://ecollab.live/" target='_blank'
                                    rel="noopener noreferrer"><button className='yliam-btn1 mt-4 y-green'
                                        style={{ background: '#0000009e', padding: '10px 20px', }}>Website <GoLinkExternal style={{ width: 18, height: 18 }} /></button></a>
                            </div>
                        </div>
                    </div>
                    </YFadeIn>
                </div>
            </div>
        </>
    )
}