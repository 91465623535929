import './App.css';
import Hero from './components/Hero';
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react';
import HeroDots from './components/HeroDots';
import { VscOrganization } from 'react-icons/vsc';
import { BsJournalCode } from 'react-icons/bs';
import NavBarr from './components/navbar/NavBarr';
import MobileNav from './components/navbar/MobileNav';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { YSlideLeft } from './components/others/animate';
import { BiHome, BiMobile } from 'react-icons/bi';
import HCGFlutter from './components/experience/hcgFlutter';
import CABot from './components/experience/caBOT';
import BS from './components/projects/bs';
import WumpusWorld from './components/projects/wumpus';
import { useParams } from 'react-router';
import ECollab from './components/projects/eCollab';
import GAP from './components/projects/gap';
import Razhor from './components/projects/razhor';
import { GoGlobe } from 'react-icons/go';
import { FaDiscord } from 'react-icons/fa';
import { IoGameController } from 'react-icons/io5';

const title = 'William Nworah (portfolio)'

// const slidesNav = {
//   1: { name: <><BiHome size={26} style={{ marginRight: 5 }} /></> },
//   2: { name: <><VscOrganization size={26} style={{ marginRight: 5 }} /> mobile</>, color: '#770FE5', altColor: '#ffffff', dotColor: '#ffa739' },
//   3: { name: <><VscOrganization size={26} style={{ marginRight: 5 }} /> automation</>, color: '#5865F2', altColor: '#ffffff', dotColor: '#ffffff' },
//   4: { name: <><GoGlobe size={26} style={{ marginRight: 5 }} /> boardsider</>, color: '#DEC0FF', altColor: '#1a1a1a', dotColor: '#DEC0FF' },
//   5: { name: <><FaDiscord size={26} style={{ marginRight: 5 }} /> razhor</>, color: '#00CFFF', altColor: '#000000', dotColor: '#00CFFF' },
//   6: {
//     name: <><IoGameController size={26} style={{ marginRight: 5 }} /> wumpus</>, color: '#974b00', altColor: '#ffffff', dotColor: '#ff7f00'
//   },
//   7: { name: <><GoGlobe size={26} style={{ marginRight: 5 }} /> ecollab</> },
//   // 8: { name: <><BiMobile size={26} style={{ marginRight: 5 }} /> goa ap</>, color: '#dd1c85', altColor: '#ffffff', dotColor: '#ffffff' },
// }

const slidesNav = {
  1: { name: <><BiHome size={26} style={{ marginRight: 5 }} /></> },
  2: { name: <><FaDiscord size={26} style={{ marginRight: 5 }} /> razhor</>, color: '#00CFFF', altColor: '#000000', dotColor: '#00CFFF' },
  3: { name: <><GoGlobe size={26} style={{ marginRight: 5 }} /> boardsider</>, color: '#DEC0FF', altColor: '#1a1a1a', dotColor: '#DEC0FF' },
  4: { name: <><GoGlobe size={26} style={{ marginRight: 5 }} /> ecollab</> },
  5: { name: <><IoGameController size={26} style={{ marginRight: 5 }} /> wumpus</>, color: '#974b00', altColor: '#ffffff', dotColor: '#ff7f00' },
}

function App() {
  const [cur, setCur] = useState(1);
  const [locked, setLocked] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (!isNaN(id)) {
      setCur(slidesNav[+id] ? +id : 1)
      setLocked(true)
    }
  }, [id])

  function checkWheel(event) {
    if (event.deltaY < 0) {
      setCur(slidesNav[cur - 1] ? cur - 1 : Object.keys(slidesNav).length)
    } else {
      setCur(slidesNav[cur + 1] ? cur + 1 : 1)
    }
  };

  function handleKeyboard(e) {
    if (e.keyCode === 38) {
      setCur(slidesNav[cur - 1] ? cur - 1 : Object.keys(slidesNav).length)
    } else if (e.keyCode === 40) {
      setCur(slidesNav[cur + 1] ? cur + 1 : 1)
    }
    else if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
      setLocked(!locked)
    }
  }

  useEffect(() => {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
    if (!locked)
      setTimeout(() => setCur(slidesNav[cur + 1] ? cur + 1 : 1), 10000);
  }, [cur, locked])

  // const slides = {
  //   1: <Hero cur={1} />, 2: <HCGFlutter cur={2} />,
  //   3: <CABot cur={3} />,
  //   4: <BS cur={4} />, 5: <Razhor cur={5} />,
  //   6: <WumpusWorld cur={6} />,
  //   7: <ECollab cur={7} />,
  //   //  8: <GAP cur={8} />,
  // }
  const slides = {
    1: <Hero cur={1} scrollTo={setCur} />, 2: <Razhor cur={2} />,
    3: <BS cur={3} />,
    4: <ECollab cur={4} />, 5: <WumpusWorld cur={5} />,
  }


  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <NavBarr setCur={setCur} color={slidesNav[cur].color} altColor={slidesNav[cur].altColor} />

      <MobileNav setCur={setCur} cur={cur} />
      <div className="App">
        <header className="App-header">
          <div id='hero-bg' className='hero-image' tabIndex={0} onWheel={checkWheel} onKeyDown={handleKeyboard}>
            {/* {slides[cur]} */}
            <Hero cur={cur} scrollTo={setCur} />
            <Razhor cur={cur} />
            <BS cur={cur} />
            <ECollab cur={cur} />
            <WumpusWorld cur={cur} />

            <HeroDots cur={cur} color={slidesNav[cur].dotColor ?? slidesNav[cur].color} textColor={slidesNav[cur].altColor ?? '#000000'}
              size={Object.keys(slidesNav).length} setCur={setCur} locked={locked} setLocked={setLocked} />
            <div style={{
              cursor: 'pointer', width: '100%',
              position: 'absolute', bottom: 20, zIndex: 3,
            }}>
              <YSlideLeft key={cur}>
                <div style={{
                  width: '100%',
                  display: 'flex', justifyContent: 'space-between'
                }}>
                  {slidesNav[cur - 1] ?
                    <button onClick={() => setCur(cur - 1)} className='hero-prev'>{slidesNav[cur - 1].name}<MdKeyboardArrowLeft size={20} /></button>
                    :
                    <div></div>
                  }
                  <button onClick={() => setCur(slides[cur + 1] ? cur + 1 : 1)}
                    className='hero-next' style={{ color: slidesNav[cur].dotColor }}>{slidesNav[cur + 1] ? slidesNav[cur + 1].name : slidesNav[1].name} <MdKeyboardArrowRight size={20} /></button>


                </div>
              </YSlideLeft>

            </div>
          </div>
          {/* <ExperienceList />
          <ProjectList />
          <Footer /> */}
          {/* <SkillsList title={'SKILLS'} /> */}
        </header>
      </div>
    </>
  );
}

export default App;
