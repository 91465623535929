import React, { useEffect, useState } from 'react';
import { YFadeIn } from '../others/animate';
import { GoGlobe, GoLinkExternal } from 'react-icons/go';
import { BsCodeSlash, BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import ZoomImage from '../others/zoomImage';

export default function BS({ cur }) {
    const [showModal, setShowModal] = useState(false);
    const screenWidth = useWindowSize();

    useEffect(() => {
        setShowModal(false)
    }, [cur])


    if (cur != 3)
        return (<></>)

    return (
        <>
            <ZoomImage showModal={showModal} setShowModal={setShowModal} />
            <div className={`blur-featured`} style={{}}>
                <img src={screenWidth > 800 ? '/images/projects/bs/bs2.png' : '/images/projects/bs/bs.png'} style={{ opacity: screenWidth > 1500 ? 0.3 : 0.2, height: '100vh', maxWidth: '100%', right: 0, bottom: 0, maxHeight: '100vh', position: 'absolute' }} />
                <div style={{ width: '100%', margin: 'auto' }}>
                    <YFadeIn key={cur}><div className='hero-flex'>
                        <div style={{ fontSize: 28, }} className='hero-texts'>
                            <div className='hero-heading'>PROJECT</div>
                            <a href='https://boardsider.com/'
                                target='_blank'><h3 style={{ color: '#DEC0FF' }}><GoGlobe /> BoardSider</h3></a>
                            <div className='mb-3 project-summary'>Presenting eSports the right way.</div>
                            <span>A leaderboard management platform for organizing, visualizing, and presenting eSports data.</span>

                            <div className='mt-3 project-preview' style={{ color: '#DEC0FF' }}>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/bs/bsBoard.png',
                                    title: 'Customized Leaderboards',
                                    description: `Use vertical / horizontal layout with your custom background, columns, sizes, and colors.`
                                })}><img src='/images/projects/bs/bsBoard.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/bs/bsH2H.png',
                                    title: 'Head-To-Head',
                                    description: `Team comaparison, and live match leaderboard.`
                                })}><img src='/images/projects/bs/bsH2H.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/bs/bsStats.png',
                                    title: 'Map Stats',
                                    description: `View team's performance per map.`
                                })}><img src='/images/projects/bs/bsStats.png' /></div>
                            </div>

                            <div className='mt-3 exp-tools'><BsCodeSlash color=' #DEC0FF' /> Next.js, Firebase, Framer Motion, Leaflet.js.</div>

                            <div className="hero-social">
                                <a href="https://www.boardsider.com" target='_blank'
                                    rel="noopener noreferrer"><button className='yliam-btn mt-4'
                                        style={{ background: '#0000009e', border: '1px solid #DEC0FF', color: '#DEC0FF' }}>Website <GoLinkExternal style={{ width: 18, height: 18 }} /></button></a>
                            </div>
                        </div>
                    </div>
                    </YFadeIn>
                </div>
            </div>
        </>
    )
}