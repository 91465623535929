import React from 'react';
import { BsLock, BsUnlock } from 'react-icons/bs';
import { TiLockOpen, TiLockClosed } from 'react-icons/ti';

export default function HeroDots({ cur, size, setCur, locked, setLocked, color, textColor }) {
    return (
        <>
            <div style={{
                display: 'flex', flexDirection: 'column', position: 'fixed', zIndex: 3, top: 0, right: 10, height: '100vh',
                justifyContent: 'center', alignItems: 'center'
            }}>
                {/* <div style={{ marginBottom: 10, cursor: 'pointer', fontSize: 18 }}>
                    {locked ? <BsLock onClick={() => setLocked(!locked)} /> : <BsUnlock onClick={() => setLocked(!locked)} />}
                </div> */}
                {Array.from({ length: size }).map((_, index) =>
                    <button className={cur === index + 1 ? 'blink-anim' : ''}
                        style={{
                            borderRadius: '50%', width: cur === index + 1 ? 20 : 10, height: cur === index + 1 ? 20 : 10, marginBottom: 10,
                            background: cur === index + 1 ? color ?? '#10ff00' : '',
                            border: `1px solid ${color ?? '#10ff00'}`, justifyContent: 'center',
                            display: 'flex', alignItems: 'center'
                        }}
                        onClick={() => cur == index + 1 ? setLocked(!locked) : setCur(index + 1)}>{cur == index + 1 && <>{locked ?
                            <TiLockClosed color={textColor} /> :
                            <TiLockOpen color={textColor} />}</>}</button>
                )}
                {/* <MdKeyboardArrowDown style={{ marginTop: -10 }} onClick={() => setCur((cur + 1) > size ? 1 : cur + 1)} /> */}
            </div>
        </>
    )
}