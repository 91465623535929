import React, { useEffect, useState } from 'react';
import { YFadeIn } from '../others/animate';
import { GoLinkExternal } from 'react-icons/go';
import { BsCodeSlash, BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import { FaDiscord } from 'react-icons/fa';
import ZoomImage from '../others/zoomImage';

export default function Razhor({ cur }) {
    const screenWidth = useWindowSize();
    const [members, setMembers] = useState('43,000');
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        setShowModal(false)
    }, [cur])

    if (cur != 2)
        return (<></>)

    return (
        <>
            <ZoomImage showModal={showModal} setShowModal={setShowModal} />
            <div className={`blur-featured`}>
                <img src={'/images/projects/razhor.png'} style={{ opacity: screenWidth > 1500 ? 0.3 : 0.1, height: '100vh', maxWidth: '100%', right: 0, bottom: 0, maxHeight: '100vh', position: 'absolute' }} />
                <div style={{ width: '100%', margin: 'auto' }}>
                    <YFadeIn key={cur}><div className='hero-flex'>
                        <div style={{ fontSize: 28, }} className='hero-texts'>
                            <div className='hero-heading'>PROJECT</div>
                            <a href='https://boardsider.com/'
                                target='_blank'><h3 style={{ color: '#00CFFF' }}><FaDiscord /> Razhor</h3></a>
                            <div className='mb-3 project-summary'>Automating and managing events for over <span style={{ color: '#00CFFF' }}>{members}</span> members.</div>
                            <span>A subscription-based bot for multiple events automation on discord.<br />
                            </span>
                            <div className='mt-3 project-preview' style={{ color: '#00CFFF' }}>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/razhor/admin.png',
                                    title: 'Admin Panel',
                                    description: `Manage server's (and all scrims) configurations on a single panel`
                                })}><img src='/images/projects/razhor/admin.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/razhor/scrimRegRoles.png',
                                    title: 'Add Special Registration Roles',
                                    description: `Configure scrim registration behavior (custom time and messages) for different roles.`
                                })}><img src='/images/projects/razhor/scrimRegRoles.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/razhor/scrimResult.png',
                                    title: 'Customizable Result',
                                    description: `Customizable leaderboard design, message, sizes, and teams' logos.`
                                })}><img src='/images/projects/razhor/scrimResult.png' /></div>
                            </div>
                            <div className='mt-3 exp-tools'><BsCodeSlash color=' #00CFFF' /> Discord.js, Node.js, EJS, Firebase, Spreadsheet API, Drive API.</div>

                            <div className="hero-social">
                                <a href="https://razhorbot.com" target='_blank'
                                    rel="noopener noreferrer"><button className='yliam-btn1 mt-4'
                                        style={{
                                            background: '#00CFFF', borderWidth: 0, marginRight: 10, padding: 15,
                                            color: '#000000'
                                        }}>Website <GoLinkExternal style={{ width: 18, height: 18 }} /></button></a>
                                {/* <a href="https://razhorbot.com" target='_blank'
                                    rel="noopener noreferrer"><button className='yliam-btn mt-4'
                                        style={{ background: '#0000009e', padding: 15, border: '1px solid #00CFFF', color: '#00CFFF' }}>Discord <GoLinkExternal style={{ width: 18, height: 18 }} /></button></a> */}
                            </div>
                        </div>
                    </div>
                    </YFadeIn>
                </div >
            </div >
        </>
    )
}