import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { projectImages } from '../strings';
import { VscPreview } from 'react-icons/vsc'
import { GiSoundWaves, GiGearStickPattern } from 'react-icons/gi'
import { BiBarcodeReader, BiSend } from 'react-icons/bi'
import { FaDatabase, FaRegUser, FaBook } from 'react-icons/fa'
import { MdOutlineEnhancedEncryption } from 'react-icons/md'
import './themes/BAM.css'
import { Link } from 'react-router-dom';
import MobileNav from '../components/navbar/MobileNav';
import NavBar from '../components/navbar/NavBar';
import { useWindowSize } from '../components/others/OtherFunctions';
import NavBarr from '../components/navbar/NavBarr';

const title = 'Barcode Attendance Management (BAM)'
const techs = [
  { name: 'React', img: '/images/icons/react-original.svg' },
  { name: 'Adobe Xd', img: '/images/icons/Xd.png' },
]

const resources = [
  '/images/icons/github.svg',
]
export default function BAM() {

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = 'theme-bam'
  }, [])

  return (<>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <NavBarr color={'#ffffff'} altColor={'#475077'} />

    <MobileNav />
    <div className="App">
      <header className="App-header">
        <section className='my-10 px-3 w-full max-w-[1800px]'>
          <header className='text-2xl font-bold pt-10 text-center'>
            <h2>{title}</h2>
            <div className='project-date'>June, 2022</div>
          </header>
          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg '>
                <img src={projectImages.bam.preview} alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  A mobile application that utilizes the Barcode on students' ID cards to automate students' attendance management process.
                  <br /><br />
                  Topics covered:
                  <li><a href={'#db'}>Database - AsyncStorage (React Native)</a></li>
                  <li><a href={'#acct'}>Account management</a></li>
                  <li><a href={'#course'}>Course management</a></li>
                  <li><a href={'#pattern'}>Pattern configuration and recognition</a></li>
                  <li><a href={'#barcode'}>Barcode scanning</a></li>
                  <li><a href={'#speech'}>Speech synthesis</a></li>
                  <li><a href={'#submit'}>Submitting attendance</a></li>
                  <li><a href={'#view'}>View attendance</a></li>
                  <li><a href={'#encryption'}>Barcode encryption ?</a></li>
                </p>
              </div>
            </article>
          </div>

          <header className='text-2xl font-bold pt-10' id='db'>
            <h4 className='project-header'><span><FaDatabase style={{ display: 'inherit' }} /> Database</span></h4>
          </header>
          <article className='text-left'>
            <p>
              In this project, I've used <a href='https://react-native-async-storage.github.io/async-storage/docs/install' target={'_blank'} rel='noreferrer'>AsyncStorage</a> (An asynchronous, unencrypted, persistent, key-value storage system for React Native) as my database system.<br />
              Data stored on device 'A' can only be accessed and managed on device 'A'.<br />
              <li><b>ToDo</b>: Implement <Link to={'/p/blockchainCrypto'}>decentralized database (maybe using GUN.js)</Link></li>
              <div className='code'>
                <div>import AsyncStorage from '@react-native-async-storage/async-storage';</div>
                <div>//create & update data<br />
                  {`async function storeData(key, value){`}<br />
                  &emsp;{`try {`}<br />
                  &emsp;&emsp;&emsp;{`const jsonValue = JSON.stringify(value);`}<br />
                  &emsp;&emsp;&emsp;{`await AsyncStorage.setItem(key, jsonValue);`}<br />
                  &emsp;{`} catch (e) {`}<br />
                  &emsp;&emsp;&emsp;handle error
                  <br />
                  &emsp;{'}'}<br />
                  {'}'}
                </div>
                <div>//get data <br />
                  {`async function getData(key){`}<br />
                  &emsp;{`try {`}<br />
                  &emsp;&emsp;&emsp;{`const jsonValue = await AsyncStorage.getItem(key);`}<br />
                  &emsp;&emsp;&emsp;{`return jsonValue != null ? JSON.parse(jsonValue) : null;`}<br />
                  &emsp;{`} catch (e) {`}<br />
                  &emsp;&emsp;&emsp;handle error
                  <br />
                  &emsp;{'}'}<br />
                  {'}'}
                </div>
                <div>//clear all data<br />
                  {`async function clearData(){`}<br />
                  {`await AsyncStorage.clear();`}<br />
                  {'}'}
                </div>
              </div>
            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='acct'>
            <h4 className='project-header'><span><FaRegUser style={{ display: 'inherit' }} /> Account Management</span></h4>
          </header>
          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg'>
                <img src={projectImages.bam.preview} alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <li>Create a faculty account</li>
                  <li>Login as the faculty</li>
                  <li>Manage (create and delete) courses</li>
                  <li>Mark students' attendance for course.</li>
                </p>
              </div>
            </article>
          </div>
          <article className='text-left'>
            <p>
              <img src='/images/projects/BAM/cred.jpg' />
              The above is how I've structured users' credential.<br />
              To access user ID using their username ➠ 'tempCred[username].id'.<br />
              <li>You can use any authentication provider (like Firebase) to manage signup and login.</li>
              <li>If you wish to manage the credentials without any authentication provider, you might consider storing the encrypted password</li>
            </p>
          </article>


          <header className='text-2xl font-bold pt-10' id='course'>
            <h4 className='project-header'><span><FaBook style={{ display: 'inherit' }} /> Course Management</span></h4>
          </header>
          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg'>
                <img src={projectImages.bam.preview} alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <li>Add course</li>
                  <li>Assign course to multiple departments and academic years (example: Open Electives)</li>
                  <li>Delete course</li>
                </p>
              </div>
              <div className='w-full'>
                <article className='text-left'>
                  <p>
                    <img src='/images/projects/BAM/faculty.jpg' />
                    ➠ Teachers and their courses structure
                  </p>
                </article>
              </div>
            </article>
          </div>

          <header className='text-2xl font-bold pt-10' id='pattern'>
            <h4 className='project-header'><span><GiGearStickPattern style={{ display: 'inherit' }} /> Pattern</span></h4>
          </header>
          <article className='text-left'>
            <p>
              Configuring the pattern of valid roll numbers based on length, department, and academic year.<br />
              In this project, I've used the basics of JNTUHCEH's roll number system. Example: Roll number '18011A0560'<br />
              <li>18 ➠ 2018 (Year of joining college)</li>
              <li>5 ➠ CSE (Department code)</li>
              <li>60 ➠ My roll number (Student's roll number: 01,....,n)</li>
              * I did not implement section (like A (regular), IDP,...)
              <img src='/images/projects/BAM/config.jpg' />
              <li><b>deptCodePosition</b>: An array of min length 1, max length 2. Indicates the department code position in scanned roll number. <br />
                If array length = 2, then department code is from position array[0] to position array[1]</li>
              <li><b>yearCodePosition</b>: An array of min length 1, max length 2. Indicates the academic year code position in scanned roll number. <br />
                If array length = 2, then academic year code is from position array[0] to position array[1].<br />
                June, 2022 ➠ 4th year students are students who joined college in the year 2018.</li>
              * This project does not consider Lateral Entry students
            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='barcode'>
            <h4 className='project-header'><span><BiBarcodeReader style={{ display: 'inherit' }} /> Barcode Scanning</span></h4>
          </header>
          <article className='text-left'>
            <p>

            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='speech'>
            <h4 className='project-header'><span><GiSoundWaves style={{ display: 'inherit' }} /> Speech Synthesis</span></h4>
          </header>
          <article className='text-left'>
            <p>

            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='submit'>
            <h4 className='project-header'><span><BiSend style={{ display: 'inherit' }} /> Submitting Attendance</span></h4>
          </header>
          <article className='text-left'>
            <p>

            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='view'>
            <h4 className='project-header'><span><VscPreview style={{ display: 'inherit' }} /> View Attendance</span></h4>
          </header>
          <article className='text-left'>
            <p>

            </p>
          </article>

          <header className='text-2xl font-bold pt-10' id='encryption'>
            <h4 className='project-header'><span><MdOutlineEnhancedEncryption style={{ display: 'inherit' }} /> Barcode Encryption</span></h4>
          </header>
          <article className='text-left'>
            <p>

            </p>
          </article>

          <header className='text-2xl font-bold pt-10'>
            <h4 className='project-header'><span>⚙️ Tools and Technologies</span></h4>
          </header>
          <article className='text-left'>
            <p>
              <div className="flex mb-[5px]">
                {techs.map((tech, index) => (
                  <button
                    style={{
                      cursor: 'default',
                      background: `url(${tech.img})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 30,
                      marginRight: 10,
                      width: 30, height: 30, borderRadius: '50%'
                    }} key={index}></button>
                ))}
              </div>
              {techs.map((tech, index) => (
                <li key={index}>{tech.name}</li>
              ))}
            </p>
          </article>

          <header className='text-2xl font-bold pt-10'>
            <h4 className='project-header'><span>🔗 Resources</span></h4>
          </header>
          <article className='text-left'>
            <p>
              <div className="flex mb-[5px]">
                {resources.map((tech, index) => (
                  <button
                    style={{
                      cursor: 'default',
                      background: `url(${tech})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 30,
                      marginRight: 10,
                      width: 30, height: 30, borderRadius: '50%'
                    }} key={index}></button>
                ))}
              </div>
              <li>Source code: <a href='https://github.com/33yliam/Barcode-Attendance-Management' target='_blank' rel='noreferrer'>GitHub code</a></li>
            </p>
          </article>


        </section>
      </header >
    </div >
  </>)
};