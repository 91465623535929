import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { expStartIndex, proStartIndex } from '../../strings';
import { GoLinkExternal } from 'react-icons/go';
import { FaEnvelope } from 'react-icons/fa';

export default function NavBarr({ setCur, color, altColor }) {
    const [paths, setPaths] = useState(['', ''])
    const pathname = useLocation();

    useEffect(() => {
        setPaths(pathname.pathname.split('/'))
        if (pathname.pathname === '/') {
            if (pathname.hash !== '') {
                setTimeout(() => scrollToID(pathname.hash.slice(1, pathname.hash.length)), 1000)
            }
        }
    }, [pathname.pathname])

    function scrollToID(id) {
        if (document.getElementById(id))
            document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
    return (
        <Navbar variant='dark' expand="lg" style={{ position: 'absolute', width: '100%' }}>
            <Container>
                <Navbar.Brand>
                    <Link to="/"><img src='/yliam.png'
                        width='120'
                    /></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Navbar.Text>
                        <Nav className="me-auto navbar-yl">
                            {!setCur ?
                                <Link to="/" className='nav-link' onClick={() => setCur(expStartIndex)}>PORTFOLIO</Link>
                                :
                                <>
                                    {/* <Link to="" className='nav-link' onClick={() => setCur(expStartIndex)}>EXPERIENCE</Link> */}
                                    <Link to="" className='nav-link' onClick={() => setCur(proStartIndex)}>PROJECT</Link>
                                </>

                            }
                            {/* {paths.length >= 2 && paths[1] ?
                                null
                                :
                                <Link to="/#techs" className='nav-link' onClick={() => scrollToID('techs')}>TECHS</Link>
                            } */}
                            <a href="https://docs.google.com/document/d/10YfuAELgN8ophV90O4Pw3CwsfQxBTQQGcyr-4wu2yk8/edit?usp=sharing" className='nav-link' target={'_blank'}><button className='yliam-btn1'
                                style={{ background: 'transparent', display: 'flex', borderRadius: 0, alignItems: 'center', color: '#ffffff', borderWidth: 0, }}>RESUME <GoLinkExternal style={{ width: 18, height: 18, marginLeft: 5 }} /></button></a>
                            <a href="mailto:33yliam@gmail.com" className='nav-link' target={'_blank'}><button className='yliam-btn'
                                style={{ color: altColor ?? color ?? '', background: color ?? '', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><FaEnvelope style={{ marginRight: 5 }} />Let's Talk</button></a>
                        </Nav>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}