import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { projectImages } from '../strings';
import './themes/InsiderApp.css'

const title = 'Insider AF App'
const techs = [
  { name: 'Adobe Xd', img: '/images/icons/Xd.png' },
  { name: 'React Native', img: '/images/icons/react-original.svg' },
  { name: 'Firebase', img: '/images/icons/firebase.png' },
  { name: 'Insider AF API', img: '/images/icons/whiteInsider.png' },
  { name: 'Postman', img: '/images/icons/postman.svg' },
]

export default function InsiderApp() {

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = 'theme-insiderApp'
  }, [])

  return (<>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <div className="App">
      <header className="App-header">
        <section className='my-10 px-3 w-full max-w-[1800px]'>
          <header className='text-2xl font-bold pt-10 text-center'>
            <h2>{title}</h2>
            <div className='project-date'>April, 2021</div>
          </header>
          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg '>
                <source srcSet={projectImages.insiderApp.preview} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  iOS and Android application built using React Native, Insider AF API, and Firebase.            </p>
              </div>
            </article>
          </div>

          <header className='text-2xl font-bold pt-10'>
            <h4 className='project-header'><span>🖵 Screens</span></h4>
          </header>
          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg'>
                <source srcSet={projectImages.insiderApp.home} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <h3>HOME</h3>
                  <li>The first section: 2 teams from the Insider ranking</li>
                  <li>The second section: Events all over Africa</li>
                  <li>The third section: A maximum of 3 scrims for the day</li>
                </p>
              </div>
            </article>
          </div>

          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg md:order-1'>
                <source srcSet={projectImages.insiderApp.teams} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg p-order'>
                  <h3>TEAMS</h3>
                  <li>Section 1: Random video from our 5 most recent YouTube videos</li>
                  <li>The input text field allows users to easily lookup any team (using the team's code)</li>
                  <li>The last section features 8 random teams from the Insider ranking</li>
                  <br />
                  Android | iOS
                </p>
              </div>
            </article>
          </div>

          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg '>
                <source srcSet={projectImages.insiderApp.ranking} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <h3>RANKING</h3>
                  The ranking screen displays Top 20 teams from the Insider ranking
                  <li>The first section displays top 3 teams with their logo, while the next section displays 4th to 20th (without logo)</li>
                  Under the second section is the ranking updated date. The icon beside the date will have the color:
                  <li>Green: viewing most recent ranking update</li>
                  <li>Red: viewing previous ranking update</li>
                  Users can easily switch between ranking updates by clicking the ranking update date
                </p>
              </div>
            </article>
          </div>

          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg md:order-1'>
                <source srcSet={projectImages.insiderApp.transfers} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg p-order'>
                  <h3>TRANSFERS</h3>
                  The transfer screen shows the migration of players between teams
                  <li>The link icon beside a player's name indicates that the player name when pressed will link to any of the player's social account</li>
                  The arrow icon color indicates the player status:
                  <li>Green: available to join a new team (free agent)</li>
                  <li>Red: retired</li>
                  <li>White:  joined a new team</li>
                  The Free Agents section shows players not in a team, and their previous team
                </p>
              </div>
            </article>
          </div>

          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg '>
                <source srcSet={projectImages.insiderApp.scrims} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <h3>SCRIMS</h3>
                  The scrims screen consists of 2 sections
                  <li>Today's section shows scrims holding today</li>
                  It shows the scrims/organization logo, scrims name, organization tag in brackets, start time in CAT, and the tier requirements
                  <li>All scrims section shows the scrims/organization logo, scrims name, organization tag in brackets, scrim days - scrims time in CAT, and the tier requirements</li>
                  When pressed, leads the user to the organizer's social (mostly discord)
                </p>
              </div>
            </article>
          </div>

          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg md:order-1'>
                <source srcSet={projectImages.insiderApp.auth} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg p-order'>
                  <h3>AUTHENTICATION</h3>
                  Handled authentication using Firebase
                </p>
              </div>
            </article>
          </div>


          <div className='my-7 space-y-24'>
            <article className='flex flex-wrap md:justify-center md:items-center'>
              <picture className='w-full md:w-6/12 shadow-lg '>
                <source srcSet={projectImages.insiderApp.profile} sizes='(min-width: 768px) 50vw, 100vw' type='image/jpg' />
                <img loading='eager' alt='' className='rounded-lg' />
              </picture>
              <div className='flex flex-col overflow-unset  space-y-3 my-3 mx-1 w-full md:w-5/12 '>
                <p className='shadow-lg'>
                  <h3>PROFILE</h3>
                  Consists of 3 sections:
                  <li>Profile: Shows the user profile details and statistics</li>
                  <li>Predict: Allows users to predict on ongoing/upcoming events</li>
                  <li>Settings: Where users can edit and update their profile</li>
                  <br />
                  Android | iOS
                </p>
              </div>
            </article>
          </div>

          <header className='text-2xl font-bold pt-10'>
            <h4 className='project-header'><span>⚙️ Tools and Technologies</span></h4>
          </header>
          <article className='text-left'>
            <p>
              <div className="flex mb-[5px]">
                {techs.map((tech, index) => (
                  <button
                    style={{
                      cursor: 'default',
                      background: `url(${tech.img})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 30,
                      marginRight: 10,
                      width: 30, height: 30, borderRadius: '50%'
                    }} key={index}></button>
                ))}
              </div>
              {techs.map((tech, index) => (
                <li key={index}>{tech.name}</li>
              ))}
            </p>
          </article>

          {/* <header className='text-2xl font-bold pt-10'>
            <h4 className='project-header'><span>🔗 Resources</span></h4>
          </header>
          <article className='text-left'>
            <p>
              😔 We didn't launch the application.
            </p>
          </article> */}

        </section>
      </header>
    </div>
  </>)
};