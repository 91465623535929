const logo = {
    white: '/logoWhite.png',
    black: '/logoBlack.png'
}
const liam = '/liam.png'
const projectImages = {
    ecollab: { preview: '/images/projects/ecollab.gif' },
    boardsider: {
        web: {
            preview: '/images/projects/Boardsider-Web/bs.png',
            gif: '/images/projects/Boardsider-Web/gif.gif',
        },
        bot: {
            preview: '/images/projects/Boardsider-Bot/preview.png',
            report: '/images/projects/Boardsider-Bot/report.png',
            feedback: '/images/projects/Boardsider-Bot/feedback.png',
            board: '/images/projects/Boardsider-Bot/board.png',
        }
    },
    wumpusWorld: {
        preview: '/images/projects/WumpusWorld/preview.png',
        gif: '/images/projects/WumpusWorld/wumpus.gif',
        p: '/images/projects/WumpusWorld/p.png',
        s: '/images/projects/WumpusWorld/s.png',
    },
    blockchainCrypto: {
        preview: '/images/projects/BlockchainCrypto/preview.png',
    },
    lmt: {
        preview: '/images/projects/lmt/preview.png',
        complaint: '/images/projects/lmt/complaint.jpg',
        v1: '/images/projects/lmt/lmt1.jpg',
        v2: '/images/projects/lmt/lmt2.jpg',
    },
    bam: {
        preview: '/images/projects/BAM/preview.png',
    },
    insiderApp: {
        preview: '/images/projects/InsiderApp/preview.png',
        home: '/images/projects/InsiderApp/home.png',
        teams: '/images/projects/InsiderApp/teams.png',
        transfers: '/images/projects/InsiderApp/transfers.png',
        ranking: '/images/projects/InsiderApp/ranking.png',
        profile: '/images/projects/InsiderApp/profile.gif',
        scrims: '/images/projects/InsiderApp/scrims.png',
        auth: '/images/projects/InsiderApp/auth.png'
    }
}

const colors = {
    lightBlue: '#61dafb',
    link: '#fff000'
}
const projectColors = [
    '#153667',
    'rgb(255, 153, 255)',
    'pink',
    'green',
    'gold',
    'orange',
    'purple',
    'rgb(102, 102, 153)',
    'rgb(204, 102, 153)',
    'rgb(255, 204, 255)',
    'rgb(204, 255, 255)',
]
const expStartIndex = 2;
const proStartIndex = 2;

export { logo, liam, projectImages, colors, projectColors, expStartIndex, proStartIndex }