import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import "./styles/PathChangedLoading.css";
import "./styles/Scrollbar.css";
import "./views/themes/Dark.css";
import reportWebVitals from "./reportWebVitals";
import { useWindowSize } from "./components/others/OtherFunctions";
import WumpusWorld from "./views/WumpusWorld";
import BoardSiderBot from "./views/BoardSiderBot";
import InsiderApp from "./views/InsiderApp";
import LMT from "./views/LMT";
import BlockchainCrypto from "./views/BlockchainCrypto";
import BAM from "./views/BAM";
import { Helmet } from "react-helmet";

const LOADING_TIME = 1000;

function Index() {
  const [showLoading, setShowLoading] = useState(true);
  const [path, setPath] = useState([]);
  const width = useWindowSize();

  useEffect(() => {
    setTimeout(() => setShowLoading(false), LOADING_TIME);
  }, [path]);

  function pathChanged(newPath) {
    setPath(newPath);
    setShowLoading(true);
    window.scroll(0, 0);
    setTimeout(() => setShowLoading(false), LOADING_TIME);
  }
  return (
    <React.StrictMode>
      <Router>
        {showLoading ? (
          <div className="loading">
            <div className="uil-ring-css">
              <img alt="yliam" />
              <div className="spin-load"></div>
            </div>
          </div>
        ) : null}
        {/* <Sidebar
          sidebarClassName='side-bar-class'
          sidebar={<NavBar path={path} smallScreen={width > 1200 ? false : true} />}
          docked={width > 1200 ? true : false}
        > */}
        <Helmet>
          <title>William Nworah (portfolio)</title>
        </Helmet>
        <Scrollbar
          style={{ height: "100vh", width: "100%" }}
          scrollTop={0}
          noDefaultStyles={false}
          trackYProps={{ className: "trackY" }}
          thumbYProps={{ className: "thumbY" }}
          trackXProps={{ className: "trackX" }}
        >
          {/* <NavBarr />

          <MobileNav path={path} smallScreen={width > 991 ? false : true} /> */}
          <PathTracker pathChangedFun={pathChanged} />

          <MyRoutes />
        </Scrollbar>
        {/* </Sidebar> */}
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));

function MyRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/:id" element={<App />} />
      <Route exact path="/p/wumpus-world" element={<WumpusWorld />} />
      {/* <Route exact path="/p/boardsider-bot" element={<BoardSiderBot />} /> */}
      <Route exact path="/p/insider-app" element={<InsiderApp />} />
      {/* <Route exact path="/p/lmt" element={<LMT />} /> */}
      <Route exact path="/p/blockchainCrypto" element={<BlockchainCrypto />} />
      <Route exact path="/p/bam" element={<BAM />} />

      <Route path="*" element={<App />} />
    </Routes>
  );
}

function PathTracker({ pathChangedFun }) {
  const { pathname } = useLocation();

  useEffect(() => {
    let path = pathname.split("/");
    if (pathname !== "/") pathChangedFun(path);
    else pathChangedFun([]);
  }, [pathname]);
  return null;
}

reportWebVitals();
