import { AnimatePresence, motion } from "framer-motion";

function YFadeIn({ children, once }) {
    return (
        <AnimatePresence>
            <motion.div
                key={children}
                initial={{ y: '5%', opacity: 0, scale: 0.9 }}
                whileInView="visible"
                viewport={{ once: !once }}
                transition={{ duration: 0.7 }}
                variants={{
                    visible: { opacity: 1, scale: 1, y: 0 },
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence >
    );
}

function YSlideLeft({ children, once }) {
    return (
        <AnimatePresence>
            <motion.div
                key={children}
                initial={{ x: '-5%', opacity: 0, scale: 0.5 }}
                whileInView="visible"
                viewport={{ once: !once }}
                transition={{ duration: 0.6 }}
                variants={{
                    visible: { opacity: 1, scale: 1, x: 0 },
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence >
    );
}
export { YFadeIn, YSlideLeft }