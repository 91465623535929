import React, { useEffect, useState } from 'react';
import { YFadeIn } from '../others/animate';
import { GoLinkExternal } from 'react-icons/go';
import { BsCodeSlash, BsGithub, BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import { IoGameController } from 'react-icons/io5';
import ZoomImage from '../others/zoomImage';

export default function WumpusWorld({ cur }) {
    const screenWidth = useWindowSize();
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        setShowModal(false)
    }, [cur])

    if (cur != 5)
        return (<></>)

    return (
        <>
            <ZoomImage showModal={showModal} setShowModal={setShowModal} />
            <div className={`blur-featured`} style={{}}>
                {screenWidth > 900 && <img src={'/images/projects/wumpusWorld/s.png'} style={{ opacity: 0.2, height: '100vh', maxWidth: '50%', left: 0, bottom: 0, maxHeight: '100vh', position: 'absolute' }} />}
                <img src={'/images/projects/wumpusWorld/wumpus.gif'} style={{ opacity: screenWidth > 900 ? 0.4 : 0.2, height: '100vh', maxWidth: screenWidth > 900 ? '50%' : '100%', right: 0, bottom: 0, maxHeight: '100vh', position: 'absolute' }} />
                <div style={{ width: '100%', margin: 'auto' }}>
                    <YFadeIn key={cur}><div className='hero-flex'>
                        <div style={{ fontSize: 28, }} className='hero-texts'>
                            <div className='hero-heading'>PROJECT</div>
                            <a href='https://github.com/33yliam/Wumpus-World'
                                target='_blank'><h3 style={{ color: '#ff7f00' }}><IoGameController /> Wumpus World Game</h3></a>
                            <div className='mb-3 project-summary'>College Mini Project.</div>
                            <span>Worked in a team of 4 to develop a gestured controlled Wumpus World game.</span>
                            <div className='mt-3 project-preview' style={{ color: '#ff7f00' }}>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/wumpusWorld/wumpus.gif',
                                    title: 'Hand Gesture Recognition',
                                    description: `Play with keyboard or hand gesture.`
                                })}><img src='/images/projects/wumpusWorld/wumpus.gif' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/wumpusWorld/s.png',
                                    title: 'Increased Cave Size',
                                    description: ``
                                })}><img src='/images/projects/wumpusWorld/s.png' /></div>
                                <div onClick={() => setShowModal({
                                    img: '/images/projects/wumpusWorld/p.png',
                                    title: 'Standard 4x4 Rooms',
                                    description: ``
                                })}><img src='/images/projects/wumpusWorld/p.png' /></div>
                            </div>
                            <div className='mt-3 exp-tools'><BsCodeSlash color='#ff7f00' /> Python, PyGame, MediaPipe, OpenCV, EasyGUI.</div>
                            {/* 
                            <div className="hero-social">
                                <a href="/p/wumpus-world"
                                    rel="noopener noreferrer">
                                    <button className='yliam-btn mt-4'
                                        style={{ background: '#974b00', padding: '10px 20px', color: '#ffffff', marginRight: 20 }}>Read more</button></a>
                                <a href="https://github.com/33yliam/Wumpus-World" target='_blank'
                                    rel="noopener noreferrer"><button className='yliam-btn mt-4'
                                        style={{ background: '#0000009e', border: '1px solid #ff7f00', padding: '10px 20px', color: '#ff7f00' }}>Source code <BsGithub style={{ width: 18, height: 18 }} /></button></a>
                            </div> */}
                        </div>
                    </div>
                    </YFadeIn>
                </div>
            </div>
        </>
    )
}