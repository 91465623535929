import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import '../../styles/MobileNav.css'

import NavBar from './NavBar';
import { useWindowSize } from '../others/OtherFunctions';

export default function MobileNav({ setCur, cur }) {
    const { pathname } = useLocation();
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const width = useWindowSize();

    function handleMenu(i) {
        setMenuIsOpen(i)
    }

    useEffect(() => {
        handleMenu(false)
    }, [pathname, cur])

    return (
        width < 991 ?
            <Menu isOpen={menuIsOpen}
                onStateChange={(state) => handleMenu(state.isOpen)}>
                <NavBar smallScreen={true} menuIsOpen={handleMenu} setCur={setCur} />
            </Menu>
            :
            <></>
    )
}