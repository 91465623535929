import React from 'react';
import { FaLinkedinIn, FaEnvelope, FaGithub, FaTwitter } from 'react-icons/fa';
import { VscChromeClose, VscOrganization } from 'react-icons/vsc'
import { Link } from 'react-router-dom';
import { Scrollbar } from "react-scrollbars-custom";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

import '../../styles/Sidebar.css'
import { expStartIndex, proStartIndex } from '../../strings';
import { BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import { IoBriefcaseOutline } from 'react-icons/io5';
const navIconSize = 30;

export default function NavBar({ smallScreen, menuIsOpen, setCur }) {
  const width = useWindowSize();
  function scrollToID(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }
  if (width < 991)
    return (
      <ProSidebar collapsed={false}
        defaultOpen>
        <SidebarHeader>
          {smallScreen ?
            <VscChromeClose
              className='closeBTN' color='white' size={30}
              onClick={() => menuIsOpen(false)} />
            :
            null
          }
          <Link to="/"><img style={{ marginTop: smallScreen ? 50 : 0 }} alt='' /></Link>
          <div style={{ textAlign: 'center', marginBottom: 15, }}>William Nworah</div>
        </SidebarHeader>
        <SidebarContent>
          <Scrollbar style={{ height: '100%', width: '100%', }}
            trackYProps={{ className: "trackY" }}
            thumbYProps={{ className: "thumbY" }}
            trackXProps={{ className: "trackX" }}
          >
            <Menu>
              {setCur ?
                <>
                  {/* <MenuItem onClick={() => setCur(expStartIndex)}><VscOrganization /> Experience</MenuItem> */}
                  <MenuItem onClick={() => setCur(proStartIndex)}><BsJournalCode /> Project</MenuItem>
                  {/* <MenuItem onClick={() => scrollToID('techs')}>Techs</MenuItem> */}
                </>
                :
                <>
                  <MenuItem><Link to="/" /><IoBriefcaseOutline /> Portfolio</MenuItem>
                </>
              }
              <MenuItem style={{ marginTop: 20 }}><a href="https://docs.google.com/document/d/10YfuAELgN8ophV90O4Pw3CwsfQxBTQQGcyr-4wu2yk8/edit?usp=sharing" className='nav-link'
                target={'_blank'}><button className='yliam-btn1' style={{ fontSize: 16, padding: '10px 20px', }}>RESUME</button></a></MenuItem>
            </Menu>
          </Scrollbar>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="nav-footer-links" style={{ padding: 10 }}>
            {/* <a
            href="https://www.linkedin.com/in/33yliam/"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-btn"
          >
            <FaLinkedinIn size={navIconSize} color='white' style={{ background: '#008cff', padding: 3, borderRadius: 2 }} />
          </a> */}
            {/* <a
            href="https://github.com/33yliam"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-btn"
          >
            <div style={{ width: navIconSize, borderRadius: '50%' }}>
              <FaGithub size={navIconSize} color='white' />
            </div>
          </a> */}
            <a
              href="mailto:33yliam@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="sidebar-btn"
            >
              <FaTwitter size={navIconSize} color='#fff' />
            </a>
            <a
              href="mailto:33yliam@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="sidebar-btn"
            >
              <FaEnvelope size={navIconSize} color='#fff' />
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    );

  return (<></>)
}