import React, { useEffect, useState } from 'react';
import { YFadeIn } from '../others/animate';
import { GoLinkExternal } from 'react-icons/go';
import { BsCodeSlash, BsJournalCode } from 'react-icons/bs';
import { useWindowSize } from '../others/OtherFunctions';
import { FaDiscord } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';

export default function ZoomImage({ showModal, setShowModal, useColors = { bg: '#161616', text: '#ffffff' } }) {
    const screenWidth = useWindowSize();


    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}
                centered>
                <div style={{ background: useColors.bg, color: useColors.text, borderRadius: 15, paddingBottom: 30 }}>
                    <Modal.Header style={{ borderColor: useColors.text }}>
                        <Modal.Title>{showModal.title}</Modal.Title>
                        <IoClose size={30} style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)} />
                    </Modal.Header>

                    <Modal.Body style={{ textAlign: 'center', fontSize: 20, }}>
                        <img src={showModal.img} style={{ maxWidth: '100%', margin: 'auto', marginTop: 20, marginBottom: 20 }} />
                        {showModal.description}
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}