import React, { useEffect, useState } from "react";
import { FaEnvelope, FaGamepad, FaTwitter } from "react-icons/fa";
import { YFadeIn } from "./others/animate";
import { useWindowSize } from "./others/OtherFunctions";
import { BsCodeSlash } from "react-icons/bs";

export default function Hero({ cur, scrollTo }) {
  if (cur != 1) return <></>;

  return (
    <>
      <div
        className={`blur-featured`}
        style={
          {
            // backgroundImage: 'url(/images/yBG.png)',
            // height: `${100 - (100 * (document.getElementsByClassName('navbar')[0]?.clientHeight / window.innerHeight))}vh`
          }
        }
      >
        {/* <img src='/images/yOnBG.png' style={{ opacity: screenWidth > 900 ? 1 : 0.3, height: '100vh', maxWidth: '100%', right: 0, bottom: 0, maxHeight: '100vh', position: 'absolute' }} /> */}
        <div style={{ width: "100%", margin: "auto", padding: 20 }}>
          <YFadeIn key={cur}>
            <div
              className="hero-flex profile-hero"
              style={{ maxWidth: 1000, alignItems: "center" }}
            >
              <div style={{ fontSize: 22, flex: 2 }} className="hero-texts">
                <h3>William Nworah</h3>
                <div className="mb-3">
                  <b className="y-green">Software Developer </b>
                </div>
                <span>
                  I'm a Software Developer with professional experience in web,
                  bot, and mobile application development.
                  <div>
                    📖 Passionate about solving problems, I spend my free time
                    learning new skills or analyzing eSport matches.
                  </div>
                </span>
                <div className="hero-medal" style={{ marginTop: 30 }}>
                  🏅 Africa / Latin-America coding contest Internship, METI
                  Japan (2022).
                </div>
                {/* <div className='hero-medal'>🎓 Computer Science and Engineering (2022).</div>
                                <div className='hero-medal'>🏅 SIH India - College Internal Hackathon Winner (2020).</div> */}
                <div className="hero-medal">
                  🏅 Indian Council for Cultural Relations (ICCR) Scholarship
                  (2018).
                </div>

                {/* <div className='mt-3 project-preview' style={{ color: '#1bec0c' }}>
                                    <div onClick={() => scrollTo(2)}><img src='/images/projects/razhor.png' /></div>
                                    <div onClick={() => scrollTo(3)}><img src='/images/projects/bs/bsYellow.png' /></div>
                                    <div onClick={() => scrollTo(4)}><img src='/images/projects/eLogo.png' /></div>
                                </div> */}
                <div
                  className="mt-3 exp-tools hero-tech"
                  style={{ alignItems: "flex-start" }}
                >
                  <BsCodeSlash style={{ marginTop: 3 }} color="#1bec0c" />
                  JavaScript, Flutter BLoC, Next.js, React, React Native...
                </div>
                <div className="hero-social">
                  {/* <a href="mailto:33yliam@gmail.com" target='_blank' style={{ marginRight: 20 #08afff}}><button className='yliam-btn'>Get in Touch</button></a> */}
                  <a
                    href="https://twitter.com/33yliam"
                    target="_blank"
                    style={{ marginRight: 20 }}
                  >
                    <FaTwitter style={{ width: 25 }} />
                  </a>
                  <a href="mailto:33yliam@gmail.com" target="_blank">
                    <FaEnvelope style={{ width: 25 }} />
                  </a>
                  {/* <a href="https://docs.google.com/document/d/10YfuAELgN8ophV90O4Pw3CwsfQxBTQQGcyr-4wu2yk8/edit?usp=sharing" target='_blank' rel="noopener noreferrer"><button className='yliam-btn1'>Resume</button></a> */}
                </div>
              </div>

              <div className="pic">
                {/* <div className="hero-circle-container" data-anim="base container" style={{ height: 280, width: 280 }}>
                                    <div className="hero-circle hero-circle1" data-anim="base left1"></div>
                                    <div className="hero-circle hero-circle1" data-anim="base right1"></div>
                                </div> */}
                <div
                  className="hero-pic-image"
                  //   style={{ backgroundImage: `url('/images/liamBW.png')` }}
                ></div>
              </div>
            </div>
          </YFadeIn>
        </div>
      </div>

      {/* <div style={{
                cursor: 'pointer', width: '100%',
                position: 'absolute', bottom: 20, zIndex: 3,
            }}>
                <YSlideLeft key={cur}>
                    <div style={{
                        width: '100%',
                        display: 'flex', justifyContent: 'space-between'
                    }}>
                        {prev ?
                            <button onClick={() => setCur(cur - 1)} className='hero-prev'>{prev}<MdKeyboardArrowLeft size={20} /></button>
                            :
                            <div></div>
                        }
                        {next && <button onClick={() => setCur(cur + 1 > size ? 1 : cur + 1)}
                            className='hero-next'>{next} <MdKeyboardArrowRight size={20} /></button>
                        }

                    </div>
                </YSlideLeft>

            </div> */}
    </>
  );
}
